.dark {
  .text-dark {
    color: $white !important;
  }
  /*============ antd radio select ============ */
  .custom_container {
    .ant-radio {
      &.ant-radio-disabled {
        .ant-radio-inner {
          &::after {
            background-color: rgb(var(--color-gray-1));
          }
        }
      }
    }
  }

  /*===============================     Dark Antd Date Picker     ===========================================*/

  .dark-select-dropdown {
    &.ant-select {
      &.ant-select-disabled {
        background-color: rgb(68 64 60) !important;
        .ant-select-selection-item {
          .text-whiteNew-0 {
            color: #cccccc !important;
          }
        }
      }
    }
  }
  .darkInputPassword,
  .darkInputAntd {
    &.ant-select,
    &.ant-select-selection-item {
      font-family: 'Poppins', sans-serif !important;
    }
    font-family: 'Poppins', sans-serif !important;
    &:disabled {
      color: #cccccc !important;
    }

    // &.ant-input-number-disabled {
    //   background-color: rgb(68 64 60) !important;
    //   &:hover {
    //     background-color: rgb(68 64 60) !important;
    //   }
    // }

    .ant-input-number-handler-wrap {
      display: none !important;
    }
    // .ant-input-number-input-wrap {
    //   .ant-input-number-input {
    //     color: $white !important;

    //     &::placeholder {
    //       color: #818181 !important;
    //     }
    //   }
    // }
  }
  /*==========================================================================*/
  /*============ antd radio select ============ */

  .MuiTabs-root {
    .MuiButtonBase-root {
      color: rgb(var(--color-text-secondary)) !important;
      &.Mui-selected {
        background-color: var(--color-component-gray-1);
        // background-color: var(--color-hover);
        color: $white !important;
        font-weight: 500 !important;
      }
      &:hover {
        background-color: var(--color-component-gray-1) !important;
        color: $white !important;
      }
    }
    .MuiTabs-indicator {
      background-color: $purpleDark;
    }
  }

  // .form-control:not([disabled]) {
  //   color: $white !important;
  // }
  .form-control:enabled:not([type='submit']) {
    /* styles here */

    color: $white !important;
  }

  /* Dark Theme Table */

  .dataTables,
  .card_table {
    .ant-table {
      // background-color: $black;
      // background-color: var(--color-component-gray-1);
      background-color: transparent;
      overflow: hidden;
      // .ant-table-content {
      .ant-table-thead {
        .ant-table-cell {
          color: var(--color-gray-4);
          .text-gray-500,
          .text-neutral-800 {
            color: var(--color-gray-4);
          }
          border-color: var(--color-border-1);
          background-color: var(--color-component-gray-1);

          &::before {
            background-color: var(--color-border-2) !important;
          }
        }
        .ant-table-column-sorters,
        .ant-dropdown-trigger {
          .anticon {
            color: var(--color-gray-4);
            &.active {
              color: rgb(var(--color-blue-2));
            }
          }

          &.active {
            .anticon {
              color: rgb(var(--color-blue-2));
            }
          }
        }
      }
      .ant-table-tbody {
        .ant-table-cell {
          color: $white;
          .text-gray-500,
          .text-gray-700,
          .text-neutral-900,
          .text-neutral-800,
          .text-neutral-800 {
            color: $white;
          }
          .text-sky-60 {
            --tw-text-opacity: 1;
            color: rgb(var(--color-blue-2) / var(--tw-text-opacity));
          }
          .hover\:text-sky-600:hover {
            --tw-text-opacity: 1;
            color: rgb(var(--color-blue-2) / var(--tw-text-opacity));
          }
          .bg-green-100,
          .bg-green-200 {
            background-color: rgb(var(--color-green-3));
          }
          .text-green-700 {
            color: $white !important;
          }
          .border-red-600 {
            background-color: rgb(var(--color-red-3));
            color: #fff;
            border-color: #ff00005c;
          }
          .border-green-600 {
            background-color: rgb(var(--color-green-3));
            color: #fff;
            border-color: rgb(var(--color-green-3));
          }
          border-color: var(--color-border-1);

          /* For Sorter*/
          &.ant-table-column-sort {
            background-color: var(--color-component-gray-1);
          }
          &.ant-table-cell-row-hover {
            background-color: var(--color-component-gray-1);
          }
        }

        .ant-table-row-selected {
          .ant-table-cell {
            background-color: var(--color-component-gray-2);
          }
        }
        .ant-table-placeholder {
          background-color: transparent;
          .ant-empty-description {
            color: $white;
          }
          &:hover {
            .ant-table-cell {
              background-color: transparent !important;
            }
          }
        }
      }

      .ant-table-cell-scrollbar {
        box-shadow: none;
      }
      // }
    }

    .ant-table-expanded-row .ant-table-cell {
      background-color: var(--color-component-gray-1) !important;
    }
    .ant-table-row-expand-icon {
      border-color: var(--color-component-gray-2) !important;
      background-color: var(--color-component-gray-1) !important;
      color: $white !important;
      // &:hover {
      // }
    }
    .ant-spin {
      background-color: rgb(var(--color-base-3));
      backdrop-filter: blur(4px);
    }

    .ant-pagination {
      // margin-right: 16px !important;
      .ant-pagination-item {
        border-color: var(--color-component-gray-1) !important;
        background-color: var(--color-component-gray-1) !important;
        color: $white !important;
        &:hover {
          background-color: #2e2e2e !important;
        }
        &.ant-pagination-item-active {
          border-color: rgb(var(--color-base-3)) !important;
          background-color: rgb(var(--color-gray-300)) !important;
          color: $white !important;
        }
      }
      .ant-pagination-item-link,
      .ant-pagination-item-ellipsis {
        color: $white !important;
        // .anticon {
        //   color: $white !important;
        // }
      }
      .ant-pagination-next,
      .ant-pagination-prev {
        border: 1px solid var(--color-component-gray-2) !important;
        background-color: var(--color-component-gray-1) !important;
        color: $white !important;
        &:hover {
          background-color: #2e2e2e !important;
        }
        .anticon {
          color: $white !important;
        }
        &.ant-pagination-disabled {
          opacity: 0.5;
        }
      }
    }
    .ant-pagination-options {
      .ant-select {
        .ant-select-arrow {
          color: $white !important;
        }
        .ant-select-selector {
          border: 1px solid rgb(var(--color-gray-100)) !important;
          box-shadow: --color-shadow-soft;
          background-color: rgb(var(--color-gray-100)) !important;
          color: $white !important;
          &::after {
            color: $white !important;
          }
          .ant-select-selection-item {
            color: $white !important;
          }
        }
        .ant-select-dropdown {
          background-color: rgb(var(--color-gray-100)) !important;
          .ant-select-item {
            color: $white !important;
            &.ant-select-item-option-selected {
              background-color: rgb(var(--color-gray-200)) !important;
            }
          }
        }
      }
    }
  }

  /* =================== Modal Classes =============================*/
  .modal_visible {
    .text-gray-900 {
      color: rgb(var(--color-text-primary));
    }
  }

  /* ================================================*/

  .custm-select,
  .ant-select {
    .ant-select-selector {
      // border: 1px solid var(--color-component-gray-2) !important;
      border: 1px solid rgb(var(--color-gray-100)) !important;
      // background-color: var(--color-component-gray-1) !important;

      background-color: rgb(var(--color-gray-100)) !important;
      &:hover,
      &.ant-select-open {
        box-shadow: var(--color-shadow-soft) !important;
      }
      .ant-select-selection-item {
        color: $white;
      }
      .ant-select-selection-search-input {
        color: $white;
      }
    }
    &.ant-select-open {
      .ant-select-selector {
        background-color: rgb(var(--color-gray-200)) !important;
      }
    }
    &.ant-select-multiple {
      .ant-select-selector {
        .ant-select-selection-item {
          // background-color: var(--color-component-gray-2) !important;
          background-color: rgb(var(--color-gray-200)) !important;
          .anticon {
            color: $white !important;
          }
        }
      }
    }
    .ant-select-selection-search-input {
      color: $white !important;
    }
  }
  .ant-select-dropdown {
    background-color: rgb(var(--color-base-1)) !important;
    backdrop-filter: blur(20px);
    .ant-select-item {
      color: $white !important;
      &.ant-select-item-option-selected {
        background-color: var(--color-component-gray-2) !important;
      }
      &.ant-select-item-option-active {
        background-color: var(--color-component-gray-3) !important;
      }
    }
  }

  /*======================= Dark Theme Drawer =============================*/

  .drawer-root-className {
    .ant-drawer-content {
      background-color: rgb(var(--color-base-1));
      .ant-drawer-header {
        border-color: var(--color-border-2) !important;
      }
      .text-neutral-900,
      .text-gray-700 {
        color: $white;
      }
    }
  }

  .dowpdownDark {
    .text-neutral-900 {
      color: $white;
    }
    .text-sky-700 {
      color: $white !important;
      font-weight: 500 !important;
    }
  }
  .dropdownOverlay {
    &.dowpdownDark {
      .ant-dropdown-menu {
        .ant-dropdown-menu-item-selected {
          background-color: rgb(var(--color-base-2)) !important;
          .text-neutral-900 {
            color: $white;
          }
          .text-sky-700 {
            color: $white !important;
            font-weight: 500 !important;
          }
        }
        .ant-dropdown-menu-title-content {
          color: $white !important;
        }
        .ant-dropdown-menu-item-disabled {
          .ant-dropdown-menu-title-content {
            color: var(--color-component-gray-4) !important;
          }
          &:hover {
            background-color: rgb(var(--color-base-2)) !important;
          }
        }
      }
    }
  }

  .scrollCss,
  .ant-table-body,
  .ant-drawer-body,
  .ant-table-content,
  .MuiDialogContent-root,
  .MuiYearPicker-root {
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgb(2, 132, 199, 0.3) !important;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(var(--primary-1)) !important;
    }
    &::-webkit-scrollbar {
      width: 5px !important;
      height: 5px !important;
      cursor: pointer !important;
    }
  }

  .input-group-text {
    background-color: rgb(var(--color-base-2)) !important;
    border-color: var(--color-component-gray-1);
    color: $white;
  }
  /*===============================     Dark Antd Date Picker     ===========================================*/
  .dark-ant-picker {
    &.ant-picker {
      .ant-picker-input {
        color: $white !important;
        input {
          color: $white !important;

          &::placeholder {
            color: #818181 !important;
          }

          &:disabled {
            color: #cccccc !important;
            &::placeholder {
              color: $white !important;
            }
          }
        }
        .ant-picker-suffix {
          color: $white !important;
          .anticon {
            color: $white !important;
          }
        }
      }
      &.ant-picker-disabled {
        background-color: rgb(68 64 60) !important;
      }

      &.ant-picker-status-error {
        background-color: rgb(38 38 38) !important;
      }
    }
  }
  .dark-ant-picker-dropdown {
    .ant-picker-panel-container {
      background-color: rgb(23 23 23) !important;
      .ant-picker-header {
        color: #ffffffd3 !important;
        button {
          color: #ffffff79;
          &:hover {
            color: $white;
          }
        }
        .ant-picker-header-view {
          button {
            color: #ffffffd3 !important;
            &:hover {
              color: $purpleDark !important;
            }
          }
        }
      }
      .ant-picker-content {
        thead {
          tr {
            th {
              color: #ffffffd3 !important;
            }
          }
        }
        .ant-picker-cell {
          color: #ffffff79 !important;
          &:hover {
            .ant-picker-cell-inner {
              background-color: $darkGray !important;
            }
          }
          &.ant-picker-cell-in-view {
            color: $white !important;
          }
          &.ant-picker-cell-today {
            .ant-picker-cell-inner {
              // border-color: $purpleDark !important;

              &::before {
                border-color: $purpleDark !important;
              }
            }
          }
          &.ant-picker-cell-selected {
            .ant-picker-cell-inner {
              background-color: $purpleDark !important;
            }
          }
          &.ant-picker-cell-end {
          }
        }

        // ==============

        .ant-picker-time-panel-column {
          scrollbar-color: rgba(53, 53, 53, 0.747) transparent;
          .ant-picker-time-panel-cell {
            .ant-picker-time-panel-cell-inner {
              color: $white !important;
            }

            &:hover {
              .ant-picker-time-panel-cell-inner {
                background-color: $darkGray !important;
              }
            }

            &.ant-picker-time-panel-cell-selected {
              .ant-picker-time-panel-cell-inner {
                background-color: $purpleDark !important;
              }
            }
          }
        }
      }

      // ====================

      .ant-picker-footer {
        .ant-picker-ranges {
          .ant-picker-now-btn {
            color: $white !important;
            &:hover {
              color: $purpleLight !important;
            }
          }
          .ant-picker-ok {
            .ant-btn {
              background-color: $purpleDark !important;

              &:hover {
                background-color: rgb(117, 38, 214) !important;
              }

              &:disabled {
                color: $white !important;
                border-color: $purpleDark !important ;
                opacity: 0.5 !important;
              }
            }
          }
        }
      }
    }
  }
  .dark-ant-picker {
    &.ant-picker {
      .ant-picker-range-separator,
      .ant-picker-clear {
        .anticon {
          color: $white !important;
        }
      }
      .ant-picker-input {
        color: $white !important;

        input {
          color: $white !important;

          &::placeholder {
            color: #818181 !important;
          }

          &:disabled {
            color: #cccccc !important;

            &::placeholder {
              color: $white !important;
            }
          }
        }

        .ant-picker-suffix {
          color: $white !important;

          .anticon {
            color: $white !important;
          }
        }
      }

      &.ant-picker-disabled {
        background-color: rgb(68 64 60) !important;
      }

      &.ant-picker-status-error {
        background-color: rgb(38 38 38) !important;
      }
    }
    .ant-picker-clear {
      background-color: transparent !important;
    }
    .ant-picker-suffix {
      .anticon {
        &.anticon-calendar {
          color: $white !important;
        }
      }
    }
  }
  .dark-ant-picker-dropdown {
    .ant-picker-panel-container {
      background-color: rgb(var(--color-base-2)) !important;

      .ant-picker-header {
        color: #ffffffd3 !important;

        button {
          color: #ffffff79;

          &:hover {
            color: $white;
          }
        }

        .ant-picker-header-view {
          button {
            color: #ffffffd3 !important;

            &:hover {
              color: $purpleDark !important;
            }
          }
        }
      }

      .ant-picker-content {
        thead {
          tr {
            th {
              color: #ffffffd3 !important;
            }
          }
        }

        .ant-picker-cell {
          color: #ffffff79 !important;

          // .ant-picker-cell-in-view {
          //   &:hover {
          //     .ant-picker-cell-inner {
          //       background-color: $darkGray !important;
          //     }
          //   }
          // }

          &.ant-picker-cell-in-view {
            color: $white !important;
            &.ant-picker-cell-in-range,
            &.ant-picker-cell-selected,
            &.ant-picker-cell-range-end {
              &::before {
                background-color: rgb(var(--color-accent-purple) / 0.3);
              }
            }
            &:hover {
              .ant-picker-cell-inner {
                background-color: unset !important;
                &::after {
                  background-color: rgb(var(--color-accent-purple) / 0.3);
                }
              }
            }
          }
          &.ant-picker-cell-range-end {
            .ant-picker-cell-inner {
              background-color: $purpleDark !important;
            }
          }

          &.ant-picker-cell-today {
            .ant-picker-cell-inner {
              // border-color: $purpleDark !important;

              &::before {
                border-color: $purpleDark !important;
              }
            }
          }

          &.ant-picker-cell-selected {
            .ant-picker-cell-inner {
              background-color: $purpleDark !important;
            }
          }

          &.ant-picker-cell-end {
          }
        }

        // ==============

        .ant-picker-time-panel-column {
          scrollbar-color: rgba(53, 53, 53, 0.747) transparent;

          .ant-picker-time-panel-cell {
            .ant-picker-time-panel-cell-inner {
              color: $white !important;
            }

            &:hover {
              .ant-picker-time-panel-cell-inner {
                background-color: $darkGray !important;
              }
            }

            &.ant-picker-time-panel-cell-selected {
              .ant-picker-time-panel-cell-inner {
                background-color: $purpleDark !important;
              }
            }
          }
        }
      }

      // ====================

      .ant-picker-footer {
        .ant-picker-ranges {
          .ant-picker-now-btn {
            color: $white !important;

            &:hover {
              color: $purpleLight !important;
            }
          }

          .ant-picker-ok {
            .ant-btn {
              background-color: $purpleDark !important;

              &:hover {
                background-color: rgb(117, 38, 214) !important;
              }

              &:disabled {
                color: $white !important;
                border-color: $purpleDark !important;
                opacity: 0.5 !important;
              }
            }
          }
        }
      }
    }
  }
  /*===============================     Dark Antd Date Picker End    ===========================================*/
  // .form-select {
  //   background-color: rgb(23 23 23) !important;
  // }
  .ant-input-number-input {
    color: $white !important;
  }
  .ant-input-group-addon {
    background-color: rgb(var(--color-base-2)) !important;
    border-color: var(--color-component-gray-1);
    color: $white;
  }
  .ant-input-group-wrapper {
    .ant-input {
      background-color: rgb(var(--color-base-1)) !important;
      border-color: var(--color-component-gray-1);
      color: $white;
      &::placeholder {
        color: rgb(148 163 184 / var(--tw-text-opacity));
      }
      &:focus {
        outline: none !important;
      }
    }
  }
  .text-amber-400 {
    color: rgb(236 180 91 / var(--tw-text-opacity));
  }

  /* ============ Color Picker Dark ==============*/

  .ant-color-picker-trigger {
    background-color: rgb(var(--color-base-1)) !important;
  }
  .ant-popover {
    .ant-popover-inner {
      background-color: rgb(var(--color-base-4)) !important;
      border: 1px solid var(--color-border-2) !important;
      .ant-color-picker-input-container {
        .ant-select-selector {
          padding-left: 4px !important;
        }
        .ant-select-arrow {
          color: $white !important;
        }
      }
      .ant-color-picker-input {
        .ant-input-affix-wrapper {
          border-color: var(--color-component-gray-1) !important;
          background-color: rgb(var(--color-base-2)) !important;
          color: $white !important;
          .ant-input-prefix {
            color: $white !important;
          }
          .ant-input {
            background-color: rgb(var(--color-base-2)) !important;
            color: $white !important;
          }
        }
      }

      .ant-collapse-header {
        .ant-collapse-expand-icon {
          color: $white !important;
        }
        .ant-collapse-header-text {
          .ant-color-picker-presets-label {
            color: $white !important;
          }
        }
      }
    }
  }
  /* ============ Color Picker Dark ==============*/

  .ant-skeleton-active .ant-skeleton-paragraph > li,
  .ant-skeleton-title,
  .ant-skeleton-image,
  .ant-skeleton-input,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-input {
    // background: linear-gradient(
    //   90deg,
    //   rgb(48 48 48 / 6%) 25%,
    //   rgb(82 82 82 / 15%) 37%,
    //   rgba(78, 78, 78, 0.06) 63%
    // );
    background: linear-gradient(
      90deg,
      rgb(36 36 36 / 13%) 25%,
      rgb(52 52 52 / 15%) 37%,
      rgb(12 12 12 / 8%) 63%
    );
    animation: mymove 1.4s ease infinite !important;
    background-size: 400% 100% !important;
  }

  /*========== Dark Date Time Picke Material UI Start ==============*/

  .MuiDialog-root {
    .MuiPaper-root {
      .MuiDialogContent-root,
      .MuiYearPicker-root {
        overflow-x: hidden;
      }
      background-color: rgb(var(--color-base-2));
      color: $white;
      .MuiTypography-root {
        color: $white;
        &.MuiTypography-overline {
          color: $white;
        }
      }
      // .MuiButtonBase-root {
      //   &.MuiPickersDay-root {
      //     &.Mui-disabled {
      //       color: rgb(var(--color-text-secondary));
      //     }
      //   }
      // }
      .MuiDayPicker-weekContainer {
        .MuiButtonBase-root {
          &.MuiButtonBase-root {
            background-color: transparent;
            color: $white;
            &:hover {
              background-color: var(--color-component-gray-2);
            }
            &.Mui-selected {
              background-color: rgb(var(--primary-1));
            }
          }
          &.Mui-disabled {
            color: rgb(var(--color-text-secondary));
          }
          &.MuiPickersDay-today {
            border-color: rgb(var(--primary-1));
          }
        }
      }
      .MuiSvgIcon-root {
        color: $white;
      }
      .MuiYearPicker-root {
        .PrivatePickersYear-yearButton {
          &:hover {
            background-color: var(--color-component-gray-2);
          }
          &.Mui-disabled {
            color: rgb(var(--color-text-secondary));
          }
          &.Mui-selected {
            background-color: rgb(var(--primary-1));
          }
        }
      }
      .MuiButtonBase-root {
        &.MuiButton-text {
          color: rgb(var(--primary-2));
        }
      }
      .MuiClockPicker-root {
        .MuiClockNumber-root {
          color: rgb(var(--color-text-secondary));
          &:hover {
            background-color: var(--color-component-gray-2);
            color: $white;
          }
          &.Mui-selected {
            color: $white;
          }
        }
        .MuiClock-pin,
        .MuiClockPointer-root {
          background-color: rgb(var(--primary-1));
        }
        .MuiClockPointer-thumb {
          background-color: $white;
          border-color: rgb(var(--primary-1));
        }
      }
    }
  }

  /*========== Dark Date Time Picke Material UI End ==============*/

  /* ==========  table filter dropdown  =============*/

  .dropdownOverlay {
    .ant-dropdown-menu-item-active {
      background-color: rgb(var(--color-base-2)) !important;
    }

    .ant-dropdown-menu-item-active {
      &:hover {
        background-color: rgb(var(--color-base-1)) !important;
      }
    }

    &.dowpdownDark {
      .ant-dropdown-menu {
        background-color: #171717;
      }

      // .ant-dropdown-menu-item-selected {
      //   background-color: #252525 !important;
      // }

      .ant-dropdown-menu-item-active:hover {
        background-color: #1b1c22 !important;
      }
    }
  }
  .ant-dropdown {
    .ant-table-filter-dropdown {
      background-color: rgb(var(--color-base-2));
      .ant-dropdown-menu-item {
        color: $white !important;
        span {
          color: $white !important;
        }

        &.ant-dropdown-menu-item-active,
        &.ant-dropdown-menu-item-selected {
          background-color: var(--color-component-gray-2);
        }
      }
    }
    .ant-dropdown-menu {
      background-color: rgb(var(--color-base-2));
    }
    .ant-table-filter-dropdown-btns {
      border-color: var(--color-border-2) !important;
      .ant-btn-link {
        color: rgb(var(--color-text-secondary));
      }
    }
  }

  .recent_order {
    .ant-dropdown {
      .ant-table-filter-dropdown {
        background-color: rgb(var(--color-base-2));

        .ant-dropdown-menu-item {
          color: $white !important;

          span {
            color: $white !important;
          }

          &.ant-dropdown-menu-item-active,
          &.ant-dropdown-menu-item-selected {
            background-color: var(--color-component-gray-2);
          }
        }
      }

      .ant-dropdown-menu {
        // background-color: rgb(var(--color-base-2));
        background-color: red;
      }

      .ant-table-filter-dropdown-btns {
        border-color: var(--color-border-2) !important;

        .ant-btn-link {
          color: rgb(var(--color-text-secondary));
        }
      }
    }
  }
  .ant-input-number-disabled {
    &.ant-input-number-outlined {
      &:disabled {
        border-color: var(--color-border-2) !important;
        background-color: rgb(var(--color-gray-2) / 0.2) !important;
      }
      border-color: transparent !important;
      background-color: rgb(var(--color-gray-2) / 0.2) !important;
    }
  }
  .dropdownOverlay {
    &.title-filter-dropdown {
      .ant-dropdown-menu-item {
        &.ant-dropdown-menu-item-selected {
          background-color: rgb(var(--color-gray-300)) !important;
          .fontWeight {
            font-weight: 500;
          }
          .text-color-secondary {
            color: #cccccc;
          }
          .badgeColor {
            background-color: #182331;
            color: $white !important;
            font-weight: 500;
          }
        }
      }
    }
  }
  .ant-slider {
    .ant-slider-rail {
      background-color: rgb(96 96 96 / 48%);
    }
    .ant-slider-track {
      background-color: #0074b8;
    }
    .ant-slider-handle {
      &::after {
        background-color: #0074b8;
        box-shadow: 0 0 0 2px #119cec;
      }
    }
  }
  .ant-checkbox {
    .ant-checkbox-inner {
      background-color: var(--color-component-gray-3) !important;
      border-width: 0px !important;
    }
    &.ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: $purpleDark !important;
      }
    }
  }
  .custom_container {
    .ant-checkbox {
      &.ant-checkbox-checked {
        .ant-checkbox-inner {
          background-color: rgb(var(--color-blue-3)) !important;
        }
      }
    }
  }
  .ant-input-status-error {
    background-color: rgb(var(--color-gray-200)) !important;
  }
  .ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
    background: var(--color-component-gray-1) !important;
    &:after {
      border-color: rgb(var(--color-text-secondary));
    }
  }
  .form-check {
    .form-check-input {
      &:not(:checked) {
        background-color: var(--color-component-gray-3) !important;
        border-width: 0px !important;
      }
    }
  }
}
.light {
  .ant-dropdown {
    &.dropdownOverlay {
      &.title-filter-dropdown {
        .ant-dropdown-menu-item {
          &.ant-dropdown-menu-item-selected {
            background-color: var(--color-component-gray-1) !important;
            .fontWeight {
              font-weight: 600 !important;
            }
            .textColor {
              color: rgb(var(--primary-1)) !important;
            }
            .badgeColor {
              background-color: rgb(var(--primary-1));
              color: $white !important;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .ant-slider {
    .ant-slider-rail {
      background-color: rgb(96 96 96 / 48%);
    }
    .ant-slider-track {
      background-color: #0074b8;
    }
    .ant-slider-handle {
      &::after {
        background-color: #0074b8;
        box-shadow: 0 0 0 2px #119cec;
      }
    }
  }
  .ant-input-number-disabled {
    &.ant-input-number-outlined {
      border-color: transparent !important;
    }
  }
}
.title-filter-dropdown {
  .ant-dropdown-menu-item {
    padding: 0px !important;
  }
}
.ant-checkbox.ant-checkbox-checked.ant-checkbox-disabled .ant-checkbox-inner {
  background: var(--color-component-gray-1) !important;
}
/*============== Skeleton =============*/

.ant-skeleton-content {
  .ant-skeleton-title + .ant-skeleton-paragraph,
  li {
    margin-block-start: 8px;
  }
}

.light {
  .dropdownOverlay {
    &.dowpdownDark {
      .ant-dropdown-menu {
        .ant-dropdown-menu-item-selected {
          background-color: rgb(var(--color-base-2)) !important;
        }
      }
    }
  }
  .ant-skeleton-active .ant-skeleton-paragraph > li,
  .ant-skeleton-title,
  .ant-skeleton-image,
  .ant-skeleton-input,
  .ant-skeleton.ant-skeleton-active .ant-skeleton-input {
    // background: linear-gradient(
    //   90deg,
    //   rgb(153 153 153 / 6%) 25%,
    //   rgb(173 173 173 / 15%) 37%,
    //   rgb(165 165 165 / 6%) 63%
    // );
    background: linear-gradient(
      90deg,
      rgb(116 116 116 / 6%) 25%,
      rgb(92 92 92 / 15%) 37%,
      rgb(54 54 54 / 6%) 63%
    );
    animation: mymove 1.4s ease infinite;
    background-size: 400% 100% !important;
  }

  .bannerSkeleton {
    .ant-skeleton.ant-skeleton-active .ant-skeleton-input {
      // background: linear-gradient(
      //   90deg,
      //   rgb(153 153 153 / 6%) 25%,
      //   rgb(173 173 173 / 15%) 37%,
      //   rgb(165 165 165 / 6%) 63%
      // );

      background: linear-gradient(
        90deg,
        rgba(219, 219, 219, 0.06) 25%,
        rgba(199, 199, 199, 0.15) 37%,
        rgba(170, 170, 170, 0.06) 63%
      );
      animation: mymove 1.4s ease infinite;
      background-size: 400% 100% !important;
    }
  }

  /*========== Tooltip Css ===========*/

  .ant-tooltip {
    .ant-tooltip-inner {
      color: var(--color-text-primary);
      background-color: $white;
      backdrop-filter: blur(16px);
      -webkit-backdrop-filter: blur(16px);
      box-shadow: 0 6px 16px 0 rgb(149 149 149 / 8%),
        0 3px 6px -4px rgb(131 131 131 / 12%),
        0 9px 28px 8px rgb(141 141 141 / 5%);
      border: 1px solid var(--color-border-1);
    }
  }

  /*========== Tooltip Css ===========*/
}

.form-select {
  background-position: right 0.2rem center !important;
  padding-right: 22px !important;
}

@keyframes mymove {
  0% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}

// button {
//   --color1: #36486b;
//   --color2: #bccad6;
//   font-family: cambria;
//   text-align: center;
//   background: repeating-linear-gradient(
//     45deg,
//     var(--color1),
//     var(--color1) 30px,
//     var(--color2) 30px,
//     var(--color2) 60px
//   );
//   background-clip: text;
//   color: transparent;
//   -webkit-background-clip: text;
//   animation: 40s linear 0s infinite move;
// }
// @keyframes move {
//   from {
//     background-position: 0px;
//   }

//   to {
//     background-position: 1000px;
//   }
// }

$bgColor: blue;

$textHoverColor: white;
$debug: false;
.button {
  position: relative;
  z-index: 1;
  overflow: if($debug, unset, hidden);

  text-decoration: none;

  color: $bgColor;

  border: 0.15em solid $bgColor;
  border-radius: calc(0.75em + 0.5em + 0.15em);

  transition: 4s;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: -1.5em;

    z-index: -1;

    width: 200%;
    aspect-ratio: 1;

    border: if($debug, inherit, none);
    border-radius: 40%;

    background-color: rgba($bgColor, 0.25);

    transition: 4s;
  }

  &:before {
    left: -80%;
    transform: translate3d(0, 5em, 0) rotate(-340deg);
  }

  &:after {
    right: -80%;
    transform: translate3d(0, 5em, 0) rotate(390deg);
  }

  &:hover,
  &:focus {
    color: $textHoverColor;

    &:before,
    &:after {
      transform: none;
      background-color: rgba($bgColor, 0.75);
    }
  }
}

// rotating button

// .rotateBtn {
//   position: relative;
//   &:hover {
//     border-radius: 0;
//   }
// }
// .rotateBtn::before,
// .rotateBtn::after {
//   content: '';
//   width: 0;
//   height: 2px;
//   position: absolute;
//   transition: all 0.2s linear;
//   background: #444444;
// }
// // .rotateBorder {
// //   border-radius: 6px;
// //   overflow: hidden;
// // }

// .rotateBorder::before,
// .rotateBorder::after {
//   content: '';
//   width: 2px;
//   height: 0;
//   position: absolute;
//   transition: all 0.2s linear;
//   background: #444444;
// }
// .rotateBtn:hover::before,
// .rotateBtn:hover::after {
//   width: 100%;
// }
// .rotateBtn:hover .rotateBorder::before,
// .rotateBtn:hover .rotateBorder::after {
//   height: 100%;
// }

// /*----- button 3 -----*/
// .rotateBtn::after {
//   left: 0;
//   bottom: 0;
//   transition-delay: 0.6s;
// }
// .rotateBtn .rotateBorder::after {
//   transition-delay: 0.4s;
//   right: 0;
//   bottom: 0;
// }
// .rotateBtn::before {
//   right: 0;
//   top: 0;
//   transition-delay: 0.2s;
// }
// .rotateBtn .rotateBorder::before {
//   transition-delay: 0s;
//   left: 0;
//   top: 0;
// }

// .rotateBtn:hover::after {
//   transition-delay: 0s;
// }
// .rotateBtn:hover .rotateBorder::after {
//   transition-delay: 0.2s;
// }
// .rotateBtn:hover::before {
//   transition-delay: 0.4s;
// }
// .rotateBtn:hover .rotateBorder::before {
//   transition-delay: 0.6s;
// }

.rotateBtn {
  position: relative;
  // display: block;
  // width: 60px;
  // height: 60px;
  text-align: center;
  // line-height: 63px;
  // background: #333;
  // border-radius: 50%;
  // font-size: 30px;
  // color: #666;
  // transition: 0.5s;
}

.rotateBtn::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: rgb(var(--color-base-2)) !important;
  transition: 0.2s;
  transform: scale(0.9);
  z-index: -1;
}

.rotateBtn:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px var(--color-base-2);
}

.rotateBtn:hover {
  // color: #ffee10;
  box-shadow: 0 0 5px var(--color-base-2);
  text-shadow: 0 0 5px var(--color-base-2);
}

.rotateIcon {
  position: relative;
  transition: 0.8s;
  z-index: 3;
}

.rotateBtn:hover .rotateIcon {
  transform: rotateY(360deg);
}

.boxBtn {
  position: relative;
  z-index: unset;

  &::after {
    position: absolute;
    content: '';
    top: 9px;
    left: 0;
    right: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    transform: scale(0.9) translateZ(0);
    filter: blur(15px);
    background: linear-gradient(
      to left,
      #ff5770,
      #e4428d,
      #c42da8,
      #9e16c3,
      #6501de,
      #9e16c3,
      #c42da8,
      #e4428d,
      #ff5770
    );
    background-size: 200% 200%;
    animation: animateGlow 1.25s linear infinite;
  }
}

@keyframes animateGlow {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 200% 50%;
  }
}
/*----- button 3 -----*/
